import React from "react";

interface ManPowerCardProps{
    title:string;
    items:string[]
}

const  ManpowerCard=(props:ManPowerCardProps)=>{
    return (
      <div className="card shadow">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">{props.title}</p>
        </div>
        <div className="card-body">
        {props.items.map((item:string)=> (
          <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>
          <input type="number" style={{ float: 'right', width: '71px' }} />
            <input type="number" style={{ float: 'right', width: '71px' }} />
            {item}
          </h4>
        ))}
          {/* Repeat similar structure for other inputs */}
        </div>
      </div>
    );
  }
  export default ManpowerCard