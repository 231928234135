interface PropsCard{
    title?:string;
    className?:string;
    children:any
}

const Card=(props:PropsCard)=>{
    return (  <div className="card shadow mb-4">
    {props.title&&<div className="card-header py-3">
        <h6 className="text-primary fw-bold m-0">{props.title}</h6>
    </div>
}
    <div className="card-body">
        {props.children.map((children:string)=>(<h4 className="small fw-bold" style={{ paddingBottom: "0px", marginBottom: "19px" }}><input type="number" style={{ float: "right", width: "45px" }} />{children}</h4>))}       
    </div>
</div>)

}
export default Card