import axios from "axios";
import {Project} from "../types"
export const listProjects=()=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/projects`)
}
export const addProject=(project:Project)=>{
    return axios.post(`${process.env.API_URL}/projects`,project)

}
export const updateProject=(id:number,project:Project)=>{
    return axios.put(`${process.env.API_URL}/projects/${id}`,project)

}
export const deleteProject=(id:number)=>{
    return axios.delete(`${process.env.API_URL}/projects/${id}`)

}
export const getProjectById=(id:number)=>{
    return axios.get(`${process.env.API_URL}/projects/${id}`)
}

