import React from "react";

interface CardProps{
    title:string;
    children:any;
    className?:string
}
const Card2=(cardProps:CardProps)=>{
    return( 
   
<div className="card shadow">
    <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">{cardProps.title}</p>
    </div>
    <div className="card-body">
        {cardProps.children.map((children:any)=>        <h4 className="small fw-bold" style={{ paddingBottom: "0px", marginBottom: "19px" }}><input type="number" style={{ float: "right", width: "71px" }} /><input type="number" style={{ float: "right", width: "71px" }} />{children}</h4>)}
      
    </div>
</div>
 )
}
export default Card2;