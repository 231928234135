
interface MenuProps{
    items:{name:string,icon?:any}[]
    title:string;
}
const Menu=(menuProps:MenuProps)=>{
return (
<div className=" fixed-element position-fixed p-0" style={{width:"16%"}}>
  <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark bg-primary ">
    <div className="container-fluid d-flex flex-column vh-100 p-0">
      <div className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand">
        <div className="sidebar-brand-icon rotate-n-15 me-6"><i className="fas fa-laugh-wink"></i></div>
        <div className="sidebar-brand-text mx-3 m-4"><span>{menuProps.title}</span></div>
      </div>
      <ul className="navbar-nav flex-column flex-grow-1 justify-content-start ps-0" id="accordionSidebar">
        {menuProps.items.map((item: {name:string,icon?:any}, index: number) => (
          <li className="d-flex justify-content-start m-2 ms-0" key={index}>
            <a className="nav-link d-flex align-items-center" href="index.html">
              <div>{item.icon}</div>
              <span className="ms-2">{item.name}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="text-center d-none d-md-inline">
        <button className="btn rounded-circle border-0" id="sidebarToggle" type="button"></button>
      </div>
    </div>
  </nav>
</div>
   
);
};

export default Menu