import axios from "axios";
import {Shipyard} from "../types"
export const listShipyards=()=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/shipyards`)
}
export const addPShipyard=(shipyard:Shipyard)=>{
    return axios.post(`${process.env.API_URL}/shipyards`,shipyard)
}
export const updateShipyard=(id:number,shipyard:Shipyard)=>{
    return axios.put(`${process.env.API_URL}/shipyards/${id}`,shipyard)
}
export const deleteShipyard=(id:number)=>{
    return axios.delete(`${process.env.API_URL}/shipyards/${id}`)
}
export const getShipyardById=(id:number)=>{
    return axios.get(`${process.env.API_URL}/shipyards/${id}`)
}
