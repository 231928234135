import DataTable from "../../Components/DataTable/DataTable";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import NavBar from "../../Components/NavBar/NavBar";
import Menu from "../../Components/Menu/Menu";
import React from "react";
function Shipyards() {

  return (
    <React.Fragment >
        <div className="container-fluid">
          <div className="row">
          {/*<div className="col-md-2 ps-0">

      <Menu title="Slim" items={[{name:"Dashboard"},{name:"Projects"},{name:"Shipyards"},{name:"Profile"},{name:"Register"}]}/>
  </div>*/}
      {/*<div className="col-md-10">*/}
      <div className="col-md-12">
    <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
            <Header />
            <div className="container-fluid">
                <NavBar title="Shipyards" />
                <DataTable paginationItems={[10,20,30]} title="Shipyards" headers={["Project Name","Shipyard Name","Office","Manpower","Start Date","Salary"]} data={[]} />
            </div>
        </div>
          <Footer title="Copyright © Brand 2024" />
    </div><a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i></a>
</div>
</div>
</div>
</React.Fragment>
  )

}

export default Shipyards;
