import { listGroups } from "Services/groups";
import ManpowerCard from "../../Components/ManPowerCard/ManPowerCard";
import Menu from "../../Components/Menu/Menu";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import { useEffect, useState } from "react";
import { Group } from "types";

const AddProject = () => {
  const [groups,setGroups]=useState<Group[]>([])
  const getAllGroup=async()=>{
    const groups=await listGroups();
    setGroups(groups.data.data);
    console.log(groups.data.data)

  }
useEffect(()=>{
  getAllGroup()
},[])
  return (
    <div className="container-fluid">
      <div className="row">
       {/* <div className="col-md-2 ps-0">
          <Menu
            title="Slim"
            items={[
              { name: "Dashboard" },
              { name: "Projects" },
              { name: "Shipyards" },
              { name: "Profile" },
              { name: "Register" },
            ]}
          />
          </div>*/}
        {/*<div className="col-md-10">*/}
          <div className="col-md-12">
          <h3 className="text-dark mb-4">Add new project</h3>
          <div className="row mb-3">
            {groups?.map((group:Group, index) => (
              <div key={index} className="col-lg-4">
                <div className="card mb-3"></div>
                <ProjectCard title={group.name} task={group.tasks} />
              </div>
            ))}
            <div className="col-lg-8">
              <div className="row mb-3 d-none">{/* Placeholder */}</div>
              <ManpowerCard
                title={"Manpower Cost / Sell"}
                items={["Mechanics","Sales tracking","Customer Database","Payout Details","Account setup"]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
