import { Project } from "types";
interface DataTableProps{
    title:string;
    paginationItems:number[]
    headers:string[]
    data:Project[]|undefined
}

const DataTable=(props:DataTableProps)=>{
 return(
    <div className="card shadow">
    <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">{props.title}</p>
    </div>
    <div className="card-body">
        <div className="row">
            <div className="col-md-6 text-nowrap">
                <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable"><label className="form-label">Show&nbsp;<select className="d-inline-block form-select form-select-sm">
                           {props.paginationItems.map((pagination:number)=> <option value={`${pagination}`}>{pagination}</option>)}
                          
                        </select>&nbsp;</label></div>
            </div>
            <div className="col-md-6">
                <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Search" /></label></div>
            </div>
        </div>
        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
            <table className="table my-0" id="dataTable">
                <thead>
                    <tr>
                        {props.headers.map((header)=><th>{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                {props.data &&props.data.map((project:Project)=>(

                    <tr>
                        <td>
                            {project.number}
                       </td>
                         <td>
                         {project.budget_hours}
                    </td>
                      <td>
                      {project.classification}
                 </td>
                   <td>
                   {project.contract_invoice}
              </td>
                      <td>
                            {project.costs}
                       </td>
                       <td>
                            {project.start_date}
                       </td>
                       <td>
                            {project.delivery_date}
                       </td>
                       <td>
                            {project.customer_name}
                       </td>
                       
                    </tr>))

}
                </tbody>
            </table>
        </div>
        <div className="row">
            <div className="col-md-6 align-self-center">
                <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of 27</p>
            </div>
            <div className="col-md-6">
                <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                        <li className="page-item disabled"><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span></a></li>
                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" aria-label="Next" href="#"><span aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
 )
}
export default DataTable;