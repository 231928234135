interface FooterProps{
    title:string
}
const Footer=(props:FooterProps)=>{
    return(
    <footer className="bg-white sticky-footer">
    <div className="container my-auto">
        <div className="text-center my-auto copyright"><span>{props.title}</span></div>
    </div>
</footer>
    )
}
export default Footer