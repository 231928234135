import DataTable from "../../Components/DataTable/DataTable";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import NavBar from "../../Components/NavBar/NavBar";
import Menu from "../../Components/Menu/Menu";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faProjectDiagram, faShip, faSignIn, faUser } from "@fortawesome/free-solid-svg-icons";
import { listProjects } from "Services/projects";
import { Project } from "types";

function Projects() {
  const [projects,setProjects]=useState<Project[]>()
  const getAllProjects=async()=>{
    const projects=await listProjects();
    setProjects(projects.data.data)
  }
  useEffect(()=>{
    getAllProjects()
  },[])
  return (
    <React.Fragment >
        <div className="container-fluid">
          <div className="row">
          {/*<div className="col-md-2 ps-0">

      <Menu title="Slim" items={[{name:"Dashboard",icon:<FontAwesomeIcon icon={faDashboard} />},{name:"Projects",icon:<FontAwesomeIcon icon={faProjectDiagram}/>},{name:"Shipyards",icon:<FontAwesomeIcon icon={faShip}/>},{name:"Profile",icon:<FontAwesomeIcon icon={faUser} />},{name:"Register",icon:<FontAwesomeIcon icon={faSignIn} />}]}/>
     </div>*/}
      {/*<div className="col-md-10">*/}
      <div className="col-md-12">
    <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
            <Header />
            <div className="container-fluid">
                <NavBar title="Projects" />
                <DataTable paginationItems={[10,20,30]} title="Projects" headers={[]} data={projects} />
            </div>
        </div>
          <Footer title="Copyright © Brand 2024" />
    </div><a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i></a>
</div>
</div>
</div>
</React.Fragment>
  )

}

export default Projects;
