import axios from "axios";
import {Group} from "../types"
export const listGroups=()=>{
    return axios.get(`https://testi.sfaxcharityerpapp.com/api/groups`)
}
export const addGroup=(group:Group)=>{
    return axios.post(`${process.env.API_URL}/groups`,group)

}
export const updateGroup=(id:number,group:Group)=>{
    return axios.put(`${process.env.API_URL}/groups/${id}`,group)

}
export const deleteGroup=(id:number)=>{
    return axios.delete(`${process.env.API_URL}/groups/${id}`)

}
export const getGroupById=(id:number)=>{
    return axios.get(`${process.env.API_URL}/groups/${id}`)
}

