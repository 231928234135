import Button from "../Button/Button";
import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { listShipyards } from "Services/shipyards";
import { useNavigate } from "react-router-dom";
interface NavBarProps {
  title: string;
}
const NavBar = (props: NavBarProps) => {
  const navigate=useNavigate()
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [shipyards,setShipyards]=useState<any>([])
  const getAllShipyard=async()=>{
    const shipyardata:any=await listShipyards()
    console.log("shipyards",shipyardata)
    setShipyards(shipyardata.data)
  }
  useEffect(()=>{
    getAllShipyard()
  },[showModalAdd])
  return (
    <div>
      <Button
        text={`+ Add New ${props.title.slice(0, -1)}`}
        className="btn btn-primary"
        onClick={() => setShowModalAdd(!showModalAdd)}
      />
      <h3 className="text-dark d-flex mb-4">{props.title}</h3>
      {showModalAdd && (
        <Modal
          onClose={() => setShowModalAdd(false)}
          onSubmit={() => {navigate("/projects/new-project")}}
          title="Project"
          subtitle="shipyard"
          options={shipyards.data}
        />
      )}
    </div>
  );
};
export default NavBar;
