import { FormEvent, FormEventHandler, useState } from "react";
interface ModalTaskProps{
   onSubmit:(e:FormEvent<HTMLButtonElement>)=>void
   onCancel:()=>void
}
const ModalTask = (props:ModalTaskProps) => {
    const [formData, setFormData] = useState({
      group: '',
      subgroup: '',
      itemNumber: '',
      task: '',
      from: '',
      to: '',
      mechanics: 0,
      fitters: 0,
      welders: 0,
      firefighters: 0,
      riggers: 0,
      staging: false,
      forklift: false,
      truck: false,
      manitou: false,
      crane: false,
    });
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {
      const { name, value, type, checked }:any = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    };
  
    return (
    <div className="modal"  role="dialog" style={{ display: 'block' }}>
<div className="modal-dialog modal-lg my-custom-modal" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mb-3"></div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="group"><strong>Group :</strong></label><input type="text" id="group" name="group" value={formData.group} onChange={handleChange} /></div>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="subgroup"><strong>Subgroup :</strong></label><input type="text" id="subgroup" name="subgroup" value={formData.subgroup} onChange={handleChange} /></div>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="itemNumber"><strong>n° ITEM</strong></label><input type="text" id="itemNumber" name="itemNumber" value={formData.itemNumber} onChange={handleChange} /></div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="task"><strong>TASK :</strong></label></div><textarea className="form-control-lg" style={{  width: '100%' }} value={formData.task} onChange={handleChange} name="task"></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="from"><strong>From :&nbsp;</strong></label><input className="btn" type="date" value={formData.from} onChange={handleChange} name="from" /></div><strong>Bold</strong>
                </div>
                <div className="col">
                  <div className="mb-3"><label className="form-label" htmlFor="to"><strong>To :&nbsp;</strong></label><input className="btn" type="date" value={formData.to} onChange={handleChange} name="to" /></div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div></div>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}></h4><strong style={{ marginRight: '103px', paddingBottom: '0px', marginBottom: '23px' }}><span style={{ color: 'rgb(15, 15, 16)' }}>Manpower needs :</span><br /></strong>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}><input type="number" style={{ float: 'right', width: '45px' }} value={formData.mechanics} onChange={handleChange} name="mechanics" />Mechanics</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="number" style={{ float: 'right', width: '45px' }} value={formData.fitters} onChange={handleChange} name="fitters" />Fitters</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="number" style={{ float: 'right', width: '45px' }} value={formData.welders} onChange={handleChange} name="welders" />Welders</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="number" style={{ float: 'right', width: '45px' }} value={formData.firefighters} onChange={handleChange} name="firefighters" />FireFighters</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="number" style={{ float: 'right', width: '45px' }} value={formData.riggers} onChange={handleChange} name="riggers" />Riggers</h4>
                </div>
                <div className="col">
                  <div></div>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}></h4><strong style={{ marginRight: '103px', paddingBottom: '0px', marginBottom: '23px' }}><span style={{ color: 'rgb(10, 10, 10)' }}>Logistic needs :</span></strong>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px', marginTop: '14px' }}>Staging<input type="checkbox" style={{ float: 'right', paddingBottom: '0px', marginRight: '11px', height: '40px', width: '20px' }} checked={formData.staging} onChange={handleChange} name="staging" /></h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>Forklift <input type="checkbox" style={{ float: 'right', height: '40px', width: '20px' }} checked={formData.forklift} onChange={handleChange} name="forklift" /></h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="checkbox" style={{ float: 'right', height: '40px', width: '20px' }} checked={formData.truck} onChange={handleChange} name="truck" />Truck</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}><input type="checkbox" style={{ float: 'right', height: '40px', width: '20px' }} checked={formData.manitou} onChange={handleChange} name="manitou" />Manitou</h4>
                  <h4 className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>Cran<input type="checkbox" style={{ float: 'right', height: '40px', width: '20px' }} checked={formData.crane} onChange={handleChange} name="crane" />e</h4>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-light" onClick={props.onCancel} type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary" onSubmit={props.onSubmit} type="button">Save</button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ModalTask;