import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProjectDataAdd } from "types";

interface ModalProps{
    title:string;
    options:{name:string,id:number}[];
    subtitle:string
    onClose?:()=>void
    onSubmit?:()=>void

}

const Modal=(props:ModalProps)=>{
console.log("modal options",props.options)
const [addingData,setAddingData]=useState<ProjectDataAdd>({
  name:"",
  customer:"",
   number:"",
  Shipclassification:"", 
  budgethours:"",
  Substractors:"",
  Projectcost:"",
  Substractorcost:"",
  contract_invoice:"",
  shipyard_id:""

})
useEffect(()=>{
  console.log("adding",addingData)
},[addingData])
const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  setAddingData((prevState:ProjectDataAdd) => ({
    ...prevState,
    [name]: value
  }));
};
const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const { name, value } = event.target;
  setAddingData((prevState:ProjectDataAdd) => ({
    ...prevState,
    [name]: value
  }));
};
return(
<div className="modal"  role="dialog" style={{ display: 'block' }}>
<div className="modal-dialog my-custom-modal" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <h4 className="modal-title">New {props.title}</h4><button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>

        <div className="modal-body">
        <div className="container">
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project name</h6>
            <input
              className="form-control form-control-user"
              type="text"
              name="name"
              value={addingData.name}
              onChange={handleChange}
              placeholder={`Project name `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Customer</h6>
            <input
              className="form-control form-control-user"
              type="text"
              value={addingData.customer}
              name="customer"
              onChange={handleChange}
              placeholder={`Customer `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project number</h6>
            <input
              className="form-control form-control-user"
              type="text"
              value={addingData.number}
              onChange={handleChange}
              name="number"
              placeholder={`Project number `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Ship's classification</h6>
            <input
              className="form-control form-control-user"
              type="text"
              value={addingData.Shipclassification}
              onChange={handleChange}
              name="Shipclassification"
              placeholder={`Ship's classification `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>budget hours</h6>
            <input
              className="form-control form-control-user"
              type="text"
              onChange={handleChange}
              name="budgethours"
              value={addingData.budgethours}
              placeholder={`budget hours`}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Substractors</h6>
            <input
              className="form-control form-control-user"
              type="text"
              onChange={handleChange}
              value={addingData.Substractors}
              name="Substractors"
              placeholder={`Substractors `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Project cost</h6>
            <input
              className="form-control form-control-user"
              type="number"
              value={addingData.Projectcost}
              name="Projectcost"
              onChange={handleChange}
              placeholder={`Project cost `}
            />
          </div>
          {/* Second Column */}
          <div className="col-md-6">
            <h6>Substractor cost</h6>
            <input
              className="form-control form-control-user"
              type="number"
              name="Substractorcost"
              value={addingData.Substractorcost}
              onChange={handleChange}
              placeholder={`Substractor cost `}
            />
          </div>
        </div>
        <div className="row mb-3">
          {/* First Column */}
          <div className="col-md-6">
            <h6>Contract invoice</h6>
            <input
              className="form-control form-control-user"
              type="number"
              name="contract_invoice"
              value={addingData.contract_invoice}
              onChange={handleChange}
              placeholder={`Contract invoice `}
            />
          </div>
          {/* Second Column */}
     
          <div className="col-md-6">
      
            <select name="shipyard_id" onChange={handleSelectChange} className="btn btn-secondary" style={{float: "right"}}>
                <optgroup label="This is a group">
                    {props.options.map((option:{name:string,id:number})=><option value={option.id}>{option.name}</option>)}
                </optgroup>
            </select>
            <p><strong>Select {props.subtitle}</strong></p><span><span style={{color: "rgb(12, 33, 186)"}}><Link to={"/shipyards/new-shipyard"}>Add new {props.subtitle}</Link></span></span>
        </div>
        </div>
        </div>

        <div className="modal-footer"><button className="btn btn-light" type="button" data-bs-dismiss="modal"  onClick={props.onClose}>Close</button><button className="btn btn-primary" onClick={props.onSubmit} type="button">Add</button></div>
    </div>
</div>
</div>
</div>
)

}
export default Modal