import Card from "../../Components/Card/Card";
import Card2 from "../../Components/Card/Card2";
import Card3 from "../../Components/Card/Card3";
import Menu from "../../Components/Menu/Menu";

const AddShipyard = () => {
  return (
    <div className="container-fluid ">
      <div className="row" > 
        <div className="col-md-2 ps-0">
          <Menu
            title="Slim"
            items={[
              { name: "Dashboard" },
              { name: "Projects" },
              { name: "Shipyards" },
              { name: "Profile" },
              { name: "Register" },
            ]}
          />
        </div>
        <div className="col-md-10">
          <h3 className="text-dark mb-4">Shipyard </h3>
          <div className="row mb-3">
            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-body text-center shadow">
                  <img
                    className="rounded-circle mb-3 mt-4"
                    src="assets/img/25172.jpg"
                    width="121"
                    height="117"
                  />
                  <div className="mb-3">
                    <button className="btn btn-primary btn-sm" type="button">
                      Change Photo
                    </button>
                  </div>
                </div>
              </div>
              <Card
                title="Stuff"
                children={[
                  "Mechanics",
                  "Sales Tracking",
                  "Customer Databases",
                  "Payout Details",
                  "Account setup",
                ]}
              />
            </div>
            <div className="col-lg-8">
              <div className="row mb-3 d-none">
                <div className="col">
                  <div className="card text-white bg-primary shadow">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col">
                          <p className="m-0">Peformance</p>
                          <p className="m-0">
                            <strong>65.2%</strong>
                          </p>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-rocket fa-2x"></i>
                        </div>
                      </div>
                      <p className="text-white-50 small m-0">
                        <i className="fas fa-arrow-up"></i>&nbsp;5% since last
                        month
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card text-white bg-success shadow">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col">
                          <p className="m-0">Peformance</p>
                          <p className="m-0">
                            <strong>65.2%</strong>
                          </p>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-rocket fa-2x"></i>
                        </div>
                      </div>
                      <p className="text-white-50 small m-0">
                        <i className="fas fa-arrow-up"></i>&nbsp;5% since last
                        month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Card3
                    title="shipyard informations"
                    children={["shipyard name", "shipyard size"]}
                  />
                  <Card2
                    title={"Manpower Cost / Sell"}
                    children={[
                      "Mechanics",
                      "Sales Tracking",
                      "Customer Databases",
                      "Payout Details",
                      "Account setup",
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShipyard;
