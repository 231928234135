import Projects from "./Pages/Projects/Projects";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Shipyards from "./Pages/Shipyards/Shipyards";
import AddShipyard from "./Pages/Shipyards/AddShipyard";
import routes, { RouteProp } from "./routes";

function App() {

  return (
      <div>
        <Router>
          <Routes>
            {routes.map((route:RouteProp,index:number)=><Route key={index} path={route.path} element={route.element} />)}
          </Routes>

        </Router>
      </div>
    )

}

export default App;
