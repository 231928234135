interface PropsCard{
    title?:string;
    className?:string;
    children:any
}

const Card3=(props:PropsCard)=>{
    return (        <div className="card shadow mb-3">
    <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">{props.title}</p>
    </div>
    <div className="card-body">
        <form>
            <div className="row">
                {props.children.map((children:any)=>(     <div className="col">
                    <div className="mb-3"><label className="form-label" htmlFor="username"><strong>{children}</strong></label><input className="form-control" type="text" id="username" name="username" /></div>
                </div>))}
            </div>
            <div className="row">
                <div className="col">
                    <div className="mb-3"></div>
                </div>
            </div>
            <div className="mb-3"><button className="btn btn-primary btn-sm" type="submit">Save&nbsp;</button></div>
        </form>
    </div>
</div>)

}
export default Card3