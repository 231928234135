interface ButtonProps{
    text:string,
    className:string;
    onClick:(e:React.MouseEvent<HTMLButtonElement>)=>void

}

const Button=(props:ButtonProps)=>{
    return(
   <button className={props.className} onClick={props.onClick}  type="button" style={{float: "right"}}>{props.text}</button>
)
    }

export default Button;