import AddProject from "./Pages/Projects/AddProject";
import Projects from "./Pages/Projects/Projects";
import AddShipyard from "./Pages/Shipyards/AddShipyard";
import Shipyards from "./Pages/Shipyards/Shipyards";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip } from "@fortawesome/free-solid-svg-icons";
import Login from "Pages/Auth/Login/Login";
import ModalTask from "Components/Modal/ModalTask";
export interface RouteProp{
    path:string;
    element:any;
    icon?:any
}
const routes:RouteProp[]=[
    {path:"/projects",element:<Projects/>,icon:      <FontAwesomeIcon icon={faShip} />}    ,
    {path:"/shipyards",element:<Shipyards/>,icon:      <FontAwesomeIcon icon={faShip} />
},
    {path:"/projects/new-project",element:<AddProject />,icon:      <FontAwesomeIcon icon={faShip} />},
    {path:"/shipyards/new-shipyard",element:<AddShipyard />},
    {path:"/login",element:<Login />},

]
export default routes;

