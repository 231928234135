import ModalTask from "Components/Modal/ModalTask";
import { useState } from "react";
import { Task } from "types";

interface ProjectCardProps{
    title:string,
    task:Task[]
}

const ProjectCard=(props:ProjectCardProps ) =>{
 const [showModalTask,setShowModalTask]=useState(false)
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="text-primary fw-bold m-0">{props.title}</h6>
      </div>
      <div className="card-body">
        {props.task?.map((item:Task, index:number) => (
          <h4 key={index} className="small fw-bold" style={{ paddingBottom: '0px', marginBottom: '19px' }}>
            <input type="checkbox" onChange={()=>setShowModalTask(true)} />&nbsp; {item.name} {item.editable && <i className="far fa-edit"></i>}
          </h4>
        ))}
        {showModalTask &&<ModalTask onSubmit={()=>{}} onCancel={()=>{setShowModalTask(false)}}/>}
      </div>
      
    </div>
  );
}
export default ProjectCard