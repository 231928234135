import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faFileAlt, faSearch, faSignOutAlt, faUser,faCogs,faList, faDonate, faEnvelope, faExclamationTriangle, faBars } from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {}
const Header = (props: HeaderProps) => {
  return (
    <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
      <div className="container-fluid">
        <button
          className="btn btn-link d-md-none rounded-circle me-3"
          id="sidebarToggleTop"
          type="button"
        >
      <FontAwesomeIcon icon={faBars} />
        </button>
        <form className="d-none d-sm-inline-block me-auto ms-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group"></div>
        </form>
        <ul className="navbar-nav flex-nowrap ms-auto">
          <li className="nav-item dropdown d-sm-none no-arrow">
            <a
              className="dropdown-toggle nav-link"
              aria-expanded="false"
              data-bs-toggle="dropdown"
              href="#"
            >
      <FontAwesomeIcon icon={faSearch} className="fa-sm me-2 text-gray-400" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-end p-3 animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="me-auto navbar-search w-100">
                <div className="input-group">
                  <input
                    className="bg-light form-control border-0 small"
                    type="text"
                    placeholder="Search for ..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary py-0" type="button">
                    <FontAwesomeIcon icon={faSearch} className="fa-sm me-2 text-gray-400" /> 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li className="nav-item dropdown no-arrow mx-1">
            <div className="nav-item dropdown no-arrow">
              <a
                className="dropdown-toggle nav-link"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                href="#"
              >
                <span className="badge bg-danger badge-counter">3+</span>
                <FontAwesomeIcon icon={faBell} className="fa-fw" />
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-list animated--grow-in">
                <h6 className="dropdown-header">alerts center</h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="me-3">
                    <div className="bg-primary icon-circle">
                    <FontAwesomeIcon icon={faFileAlt} className="text-white" />
                    </div>
                  </div>
                  <div>
                    <span className="small text-gray-500">
                      December 12, 2019
                    </span>
                    <p>A new monthly report is ready to download!</p>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="me-3">
                    <div className="bg-success icon-circle">
                   <FontAwesomeIcon icon={faDonate} className="text-white" />
                    </div>
                  </div>
                  <div>
                    <span className="small text-gray-500">
                      December 7, 2019
                    </span>
                    <p>$290.29 has been deposited into your account!</p>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="me-3">
                    <div className="bg-warning icon-circle">
                   <FontAwesomeIcon icon={faExclamationTriangle} className="text-white" />
                    </div>
                  </div>
                  <div>
                    <span className="small text-gray-500">
                      December 2, 2019
                    </span>
                    <p>
                      Spending Alert: We've noticed unusually high spending for
                      your account.
                    </p>
                  </div>
                </a>
                <a
                  className="dropdown-item text-center small text-gray-500"
                  href="#"
                >
                  Show All Alerts
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown no-arrow mx-1">
            <div className="nav-item dropdown no-arrow">
              <a
                className="dropdown-toggle nav-link"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                href="#"
              >
                <span className="badge bg-danger badge-counter">7</span>
                <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-list animated--grow-in">
                <h6 className="dropdown-header">alerts center</h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image me-3">
                    <img
                      className="rounded-circle"
                      src="assets/img/avatars/avatar4.jpeg"
                    />
                    <div className="bg-success status-indicator"></div>
                  </div>
                  <div className="fw-bold">
                    <div className="text-truncate">
                      <span>
                        Hi there! I am wondering if you can help me with a
                        problem I've been having.
                      </span>
                    </div>
                    <p className="small text-gray-500 mb-0">
                      Emily Fowler - 58m
                    </p>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image me-3">
                    <img
                      className="rounded-circle"
                      src="assets/img/avatars/avatar2.jpeg"
                    />
                    <div className="status-indicator"></div>
                  </div>
                  <div className="fw-bold">
                    <div className="text-truncate">
                      <span>
                        I have the photos that you ordered last month!
                      </span>
                    </div>
                    <p className="small text-gray-500 mb-0">Jae Chun - 1d</p>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image me-3">
                    <img
                      className="rounded-circle"
                      src="assets/img/avatars/avatar3.jpeg"
                    />
                    <div className="bg-warning status-indicator"></div>
                  </div>
                  <div className="fw-bold">
                    <div className="text-truncate">
                      <span>
                        Last month's report looks great, I am very happy with
                        the progress so far, keep up the good work!
                      </span>
                    </div>
                    <p className="small text-gray-500 mb-0">
                      Morgan Alvarez - 2d
                    </p>
                  </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                  <div className="dropdown-list-image me-3">
                    <img
                      className="rounded-circle"
                      src="assets/img/avatars/avatar5.jpeg"
                    />
                    <div className="bg-success status-indicator"></div>
                  </div>
                  <div className="fw-bold">
                    <div className="text-truncate">
                      <span>
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good...
                      </span>
                    </div>
                    <p className="small text-gray-500 mb-0">
                      Chicken the Dog · 2w
                    </p>
                  </div>
                </a>
                <a
                  className="dropdown-item text-center small text-gray-500"
                  href="#"
                >
                  Show All Alerts
                </a>
              </div>
            </div>
            <div
              className="shadow dropdown-list dropdown-menu dropdown-menu-end"
              aria-labelledby="alertsDropdown"
            ></div>
          </li>
          <div className="d-none d-sm-block topbar-divider"></div>
          <li className="nav-item dropdown no-arrow">
            <div className="nav-item dropdown no-arrow">
              <a
                className="dropdown-toggle nav-link"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                href="#"
              >
                <span className="d-none d-lg-inline me-2 text-gray-600 small">
                  Valerie Luna
                </span>
                <img
                  className="border rounded-circle img-profile"
                  src="assets/img/avatars/avatar1.jpeg"
                />
              </a>
              <div className="dropdown-menu shadow dropdown-menu-end animated--grow-in">
                <a className="dropdown-item" href="#">
                 <FontAwesomeIcon
                    icon={faUser}
                    className="fa-sm me-2 text-gray-400"
  />
                &nbsp;Profile
                </a>
                <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faCogs} className="fa-sm me-2 text-gray-400" />
                  &nbsp;Settings
                </a>
                <a className="dropdown-item" href="#">
               <FontAwesomeIcon icon={faList} className="fa-sm me-2 text-gray-400" />
                  &nbsp;Activity log
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faSignOutAlt} className="fa-sm me-2 text-gray-400" />
                  &nbsp;Logout
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Header;
